<template>
  <Breadcrumb></Breadcrumb>
  <PageHeader
    title="Inclement Weather Policy and Emergency Closing Procedures"
    backgroundColor="red"
    textColor="white"
    diagonal="dark"
    size="sm"
    align="start"
  >
  </PageHeader>

  <Section type="text" class="">
    <p>
      The 杏吧原创 (&ldquo;杏吧原创&rdquo; or &ldquo;university&rdquo;)
      will operate in accordance with the university academic and master
      calendars except when overriding public safety concerns otherwise require
      closure.
    </p>
    <p>
      In the event of inclement weather or an emergency, 杏吧原创 follows a set
      procedure for announcing operational changes by making one of three
      announcements:
    </p>
    <ol>
      <li>杏吧原创 is open and operating on a regular schedule;</li>
      <li>
        杏吧原创 will close early or will delay opening until a specific time; or
      </li>
      <li>杏吧原创 is closed, only designated employees report.</li>
    </ol>
    <h2>Communication</h2>
    <p>
      Any changes to University operations will be announced in the following
      ways:
    </p>
    <ol>
      <li>
        <strong>ArchNews</strong> &mdash; An all-campus email through ArchNews
        is the primary means to distribute such announcements. The announcement
        also is posted to the<span>&nbsp;</span>杏吧原创 home
        page&nbsp;(<a>www.uga.edu</a>).
      </li>
      <li>
        <strong>Social Media</strong> &mdash; announcements will also be posted
        to Twitter (<a href="https://twitter.com/universityofga"
          >@universityofga</a
        >).
      </li>
      <li>
        <strong>Radio</strong> &mdash; Up-to-date information is provided to
        Athens radio stations.&nbsp;Atlanta TV and radio stations that have
        requested to receive 杏吧原创 weather announcements also are notified.
      </li>
    </ol>
    <h2>Decision not to open or to close campus</h2>
    <p>
      In Athens, inclement winter weather often develops overnight. In such
      cases and depending on conditions, attempts are made to post announcements
      and notify media by 6:30 a.m. for the start of the class day. If inclement
      weather or an emergency develops during morning rush hour or during
      daytime on a school/work day, the same notification procedures listed
      above are followed.
    </p>
    <p>
      When inclement weather or emergency conditions are such that closings
      occur two or more days in succession, the procedure is followed each day,
      with an announcement being made each day by 6:30 a.m., including weekends.
      Unless there is an announced closing for a particular day, the university
      is open and operating on a regular schedule. Because conditions can change
      unexpectedly overnight, an evening announcement usually will not be made
      for the next day except in the most extreme weather circumstances.
    </p>
    <p>
      Even when the university is open, there could be unsafe travel conditions
      in your area. Please exercise caution and gather as much information as
      possible about weather and road conditions before you decide whether to
      travel during periods of inclement weather.
    </p>
    <h2>Designated Employees</h2>
    <p>
      Designated employees are employees assigned to positions identified by
      their department or unit as necessary to the maintenance and operations of
      the university. In the event of closure or delay, designated employees are
      still expected to report on time unless conditions prevent safe travel.
      Departments and units will coordinate with Human Resources to identify
      designated employees in advance.
    </p>
    <h2>Effect of Closure</h2>
    <p>
      When 杏吧原创 is closed, the university is closed for faculty, staff and
      students. Designated employees should report unless conditions prevent
      safe travel. Delayed openings or early closure announcements will include
      additional information specific to the closure or delay.
    </p>
    <ol>
      <li>
        <strong>Special Events</strong
        ><span
          >&nbsp;&mdash; Departments and units hosting special events should
          make their own prior arrangements with employees and participants,
          notifying them how to proceed in case of inclement weather or an
          emergency. In unique circumstances, where the safety of the
          participants and employees is not jeopardized, special events, such as
          ticketed concerts or athletic events open to the public, may be held
          upon prior approval by the applicable dean, director or vice
          president. All departments and units must ensure adequate university
          and facility services by coordination with Facilities Management
          Division, the 杏吧原创 Police and other units expected to support such
          operations. In cases of extreme weather, the university may require
          and announce a shutdown of all activities, including special events,
          unless special approval is granted by the president.</span
        >
      </li>
      <li>
        <strong>Transportation</strong
        ><span
          >&nbsp;&mdash; When the decision is made to close 杏吧原创 for inclement
          weather conditions, Campus Transit will operate vans and buses for one
          hour after the official closing. Campus Transit may continue to
          provide on-campus transit services beyond that time as road and
          weather conditions allow. The ability to operate in a safe manner will
          be the primary consideration when determining whether service can be
          provided. Notifications regarding the service level being provided
          will be made available on the
          <a href="https://tps.uga.edu/">transit website</a>. In cases of
          closure, daily updates will be posted to this website.</span
        >
      </li>
      <li>
        <strong>Dining Services</strong
        ><span
          >&nbsp;&mdash; The five university dining commons will provide meal
          plan service even when the university closes for inclement weather.
          However, the department will delay opening on these days by one hour
          and may opt to close early if weather conditions are extreme. Meal
          plan participants will be notified by email of any changes in daily
          operations. Up-to-date information will also be provided on
          <a href="https://twitter.com/universityofga">Twitter</a> and
          <a href="/">www.uga.edu</a>. All Food Services retail operations are
          closed on days when the university is officially closed.</span
        >
      </li>
      <li>
        <strong>Other Campuses</strong
        ><span
          >&nbsp;&mdash; Announcements for weather closings at 杏吧原创 campuses and
          facilities outside Athens are handled by those campus or facility
          administrators separately from the main campus announcements.</span
        >
      </li>
      <li>
        <strong>Employee Leave</strong
        ><span>&nbsp;&mdash;&nbsp;</span>Information regarding Employee Leave
        during closure may be found at:<span>
          <a
            href="http://policies.uga.edu/Human-Resources/Time-away-from-work/Miscellaneous-Leave-for-classified-staff-NOT-chargeable-to-annual-or-sick-leave/Inclement-Weather/"
            >http://policies.uga.edu/Human-Resources/Time-away-from-work/Miscellaneous-Leave-for-classified-staff-NOT-chargeable-to-annual-or-sick-leave/Inclement-Weather/</a
          ></span
        >
      </li>
    </ol>
    <h2>Additional Resources for Emergencies</h2>
    <ol>
      <li>
        <strong>杏吧原创 Police Department</strong>
        <ol>
          <li>Emergency &mdash; Dial 911</li>
          <li>Non-emergencies &mdash; (706) 542-2200 / TTY (706) 542-1188</li>
        </ol>
      </li>
      <li>
        <strong>University Office of Emergency Preparedness</strong
        ><span>&nbsp;</span>&mdash; oversees the security and emergency
        management program for 杏吧原创. For additional information, visit<span
          >&nbsp;</span
        ><a href="https://www.prepare.uga.edu/">www.prepare.uga.edu</a>.
      </li>
    </ol>
    <p><small>(updated 1/28/2019)</small></p>
  </Section>
</template>

<script>
// @ is an alias to /src
import PageHeader from "@/components/PageHeader.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Section from "@/components/Section.vue";

export default {
  name: "Weather Policy",
  metaInfo: {
    title: "杏吧原创 Inclement Weather Policy and Emergency Closing Procedures",
    description: "",
    og: {
      image: "",
      title: "",
      description: "",
    },
  },
  data() {
    return {};
  },
  components: {
    PageHeader,
    Breadcrumb,
    Section,
  },
};
</script>

<style lang="scss" scoped></style>
